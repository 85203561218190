import React from 'react';
import { Field, Form, Formik } from 'formik';
import { submitMessage } from '../../apis';
import Layout from '../../layouts/layout';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import WavingHand from '../../components/WavingHand/WavingHand';
import ICON_EMAIL from '../../images/contact-email.png';
import ICON_TALK from '../../images/contact-talk.png';
import ICON_ADDRESS from '../../images/contact-address.png';
import './contact.scss';

const Contact = (): JSX.Element => (
  <Layout title="Contact" path="contact">
    <div className="contact">
      <Header />
      <div className="contact-container">
        <h1 data-sal="slide-up" data-sal-duration="600">
          Contact
        </h1>
        <h2 data-sal="slide-up" data-sal-duration="600">
          Let's start something new together!
        </h2>
        <div className="contact-banner">
          <div className="contact-banner-item" data-sal="zoom" data-sal-duration="300">
            <img src={ICON_EMAIL} alt="" />
            <p className="contact-banner-title">Email Us</p>
            <a href="mailto:hello@app-bar.com" className="contact-banner-desc">
              hello@app-bar.com
            </a>
          </div>
          <div className="contact-banner-item" data-sal="zoom" data-sal-duration="300" data-sal-delay="100">
            <img src={ICON_TALK} alt="" />
            <p className="contact-banner-title">Let’s talk!</p>
            <a target="_blank" rel="noreferrer" href="https://wa.me/85261032979" className="contact-banner-desc">
              +852 6103 2979
            </a>
          </div>
          <div className="contact-banner-item" data-sal="zoom" data-sal-duration="300" data-sal-delay="200">
            <img src={ICON_ADDRESS} alt="" />
            <p className="contact-banner-title">Find us</p>
            <a
              href="https://goo.gl/maps/e927rAFwcEBe366a8"
              target="_blank"
              rel="noreferrer"
              className="contact-banner-desc"
            >
              Unit 2B, 128 Wellington Street, Central, Hong Kong
            </a>
          </div>
        </div>
        <div className="contact-details">
          <div className="contact-map">
            <iframe
              title="Our location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.850382026545!2d114.15172921455641!3d22.283656749130945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340401090a88f111%3A0xde0b4c6c9d1ab965!2sApp%20Bar!5e0!3m2!1sen!2shk!4v1628584941761!5m2!1sen!2shk"
              loading="lazy"
              width="100%"
              height="640px"
            />
          </div>
          <div className="contact-form" id="contact-form">
            <h1 data-sal="slide-up" data-sal-duration="600">
              DROP US A LINE
            </h1>
            <Formik
              initialValues={{ name: '', email: '', message: '', terms: false }}
              onSubmit={({ name, email, message, terms }) => {
                submitMessage(name, email, message, terms)
                  // eslint-disable-next-line no-alert
                  .then(() => alert('Message successfully sent, thank you!'))
                  // eslint-disable-next-line no-alert
                  .catch(() => alert('Failed to sent message'));
              }}
            >
              {({ errors, touched, values }) => (
                <Form>
                  <div className="contact-form-row">
                    <div className="contact-form-row-item" data-sal="slide-up" data-sal-duration="600">
                      <Field
                        name="name"
                        type="text"
                        placeholder="Name"
                        validate={(value: string) => (value.length === 0 ? 'Required Field' : undefined)}
                      />
                      <span className="contact-form-error">{touched.name ? errors.name : ''}</span>
                    </div>
                  </div>
                  <div className="contact-form-row">
                    <div className="contact-form-row-item" data-sal="slide-up" data-sal-duration="600">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        validate={(value: string) => {
                          if (value.length === 0) {
                            return 'Required Field';
                          }
                          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                            return 'Invalid Email';
                          }
                          return undefined;
                        }}
                      />
                      <span className="contact-form-error">{touched.email ? errors.email : ''}</span>
                    </div>
                  </div>
                  <div className="contact-form-row">
                    <div className="contact-form-row-item" data-sal="slide-up" data-sal-duration="600">
                      <Field
                        name="message"
                        as="textarea"
                        placeholder="Message"
                        validate={(value: string) => (value.length === 0 ? 'Required Field' : undefined)}
                      />
                      <span className="contact-form-error">{touched.message ? errors.message : ''}</span>
                    </div>
                  </div>
                  <div className="contact-form-row">
                    <div className="contact-form-row-item">
                      <label htmlFor="checkbox-terms" className="contact-form-checkbox-wrapper">
                        <input type="none" style={{ display: 'none' }} />
                        <Field
                          id="checkbox-terms"
                          name="terms"
                          type="checkbox"
                          className={`contact-us-checkbox ${values.terms ? 'mod-checked' : ''}`}
                        />
                        <span className="contact-us-label">
                          I want to receive news, feature updates, discounts and offers from App Bar
                        </span>
                      </label>
                      <span className="contact-form-error">{touched.terms ? errors.terms : ''}</span>
                    </div>
                  </div>
                  <div className="contact-form-row">
                    <button type="submit" data-sal="slide-up" data-sal-duration="600">
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <WavingHand />
      <Footer minimal />
    </div>
  </Layout>
);

export default Contact;
