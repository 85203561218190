import React from 'react';
import WAVING_HAND from '../../images/home-hand.gif';
import './WavingHand.scss';

const WavingHand = (): JSX.Element => (
  <button
    type="button"
    className="waving-hand"
    onClick={() => {
      const contactUsElement = document.getElementById('contact-form') || document.getElementById('contact-us');
      const contactUsY = contactUsElement?.offsetTop || 0;
      if (contactUsY > 0) {
        window.scrollTo(0, contactUsY);
      }
    }}
  >
    <img src={WAVING_HAND} alt="Hi!" />
  </button>
);

export default WavingHand;
